export const companyStates = {
  test: { id: 0 },
  active: { id: 1 },
  blocked: { id: 2 },
  integrated: { id: 3 },
  MEKANICGO_WEB: { id: 4 },
  MAKENA_ADMIN: { id: 5 },
  MAKENA_CLIENT: { id: 6 },
  PO360: { id: 7 }
}
